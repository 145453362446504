<template>
  <Head>
    <Title>[admin] Вход</Title>
  </Head>
  <div
    class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center md:scale-125"
  >
    <form
      class="mx-auto flex w-[400px] flex-col gap-4 rounded-lg p-4 font-['Montserrat'] text-[14px] shadow-[0_.5em_1em_-.125em_rgba(10,10,10,.1),0_0_0_1px_rgba(10,10,10,.02)]"
      @submit.prevent="userLogin"
    >
      <div class="flex w-full flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label class="font-[600]">Логин</label>
          <input
            v-model="loginData.email"
            class="input w-full border-gray-300 outline-none transition-all focus:border-gray-800 focus:outline-none"
            placeholder="example@mail.ru"
            autocomplete="email"
          />
        </div>
        <div class="flex flex-col gap-2">
          <label class="font-[600]">Пароль</label>
          <YmiInput
            v-model="loginData.password"
            size="md"
            type="password"
            class="text-lg"
          />
        </div>

        <YmiButton
          type="submit"
          label="ВОЙТИ"
          size="md"
          class="bg-[#3d4451] text-white transition-all duration-300 hover:bg-slate-900"
          :loading="userStore.isLoading"
        />
      </div>
    </form>
  </div>
</template>
<script setup>
import { useUserStore } from '~/store/user';

definePageMeta({
  layout: 'empty',
});

const userStore = useUserStore();

const loginData = ref({
  email: '',
  password: '',
});

const userLogin = async () => {
  await userStore.login(loginData.value);
};
</script>
<style scoped>
input::placeholder {
  opacity: 0.9;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 18px;
}
</style>
